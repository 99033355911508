import React from "react"
import { graphql } from 'gatsby'
import get from 'lodash/get'
import SEO from '../components/seo'
import Layout from "../containers/layout/layout"
import Header from '../containers/layout/header'
import Footer from '../containers/layout/footer'
import ErrorArea from '../containers/404/error-area'

class NotFoundPage extends React.Component {
  render() {
    const [author] = get(this, 'props.data.allContentfulPerson.edges')
    return (
      <Layout>
        <SEO title="404" />
        <Header authorData={author.node}/>
        <div className="main-content">
          <ErrorArea/>
        </div>
        <Footer/>
      </Layout>
    )
  }
}

export default NotFoundPage
 
export const notFoundPageQuery = graphql`
  query {
    allContentfulPerson(filter: { id: { eq: "801d0181-aa93-5da9-82f1-fae3b71554af" } }) {
      edges {
        node {
          ...ContentfulPersonField
        }
      }
    }
  }
`
